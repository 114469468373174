<template>
  <svg
    class="d-flex pointer icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 12H20.25"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 5.25L20.25 12L13.5 18.75"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
